import { ChakraProvider, Flex, extendTheme } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import AdminHeader from '../AdminHeader/AdminHeader';
import ADMIN_HEADER_LIST from '../AdminHeader/AdminHeaderList';
import SideMenu from '../SideMenu/SideMenu';
import { useSession } from 'next-auth/react';

function Layout({ children }: any) {
	const router = useRouter();
	const { data: session } = useSession()

	const [isNextMatch, setIsNextMatch] = useState(false);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			// 윈도우 객체가 존재할 때만 실행 함으로써 정상적으로 화면 랜더링이 이뤄짐
			// ChakraUI 에서 이슈가 생김.
			setIsNextMatch(true);
		}
	}, []);

	const returnElement = () => {
		const path = router.pathname;
		const theme = extendTheme({
			fonts: {
				heading: `Pretendard, sans-serif`,
				body: `'Pretendard', sans-serif`,
			},
		});

		if (path.includes('admin')) {
			!(session && session.user?.admin) && router.push('/');

			return (
				<ChakraProvider>
					<AdminHeader headerList={ADMIN_HEADER_LIST} />
					<Flex>
						<SideMenu width={'12%'} />
						<Flex w='88%' h={'100vh'} direction='column' backgroundColor='#f1f1f1' padding='10px' pt='90px'>
							{children}
						</Flex>
					</Flex>
				</ChakraProvider>
			);
		} else {
			return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
		}
	};

	if (isNextMatch) {
		return <>{returnElement()}</>;
	} else {
		return <></>;
	}
}

export default Layout;
