import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ItemReply } from 'types/admin/system/categoryReply';

type InitalStateType = {
	contentsData: ItemReply[];
};

const initialState: InitalStateType = {
	contentsData: [],
};

const contents = createSlice({
	name: 'contents',
	initialState,
	reducers: {
		setContents(state: InitalStateType, action: PayloadAction<ItemReply[]>) {
			state.contentsData = action.payload;
		},
		addContents(state: InitalStateType, action: PayloadAction<ItemReply>) {
			state.contentsData.push(action.payload);
		},
	},
});

export const { setContents, addContents } = contents.actions;

export default contents.reducer;
