import { AnyAction, CombinedState, combineReducers } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

// Slice
// import week from './createSlice';
// import food from './foodOrder';
// import stayDay from './stayDaySlice';
// import mainData from './mainSlice';
// import favor from './favorSlice';
// import seat from './seatSlice';
import contents from './contentsSlice';
import list from './selectedList';

const persistConfig: any = {
	key: 'stayInfos',
	storage,
	whitelist: ['stayDay', 'seat', 'mainData'], // 적용 대상목록
	// blacklist: ['week', 'food', 'favor'], // 제외 대상목록
};
const reducer = (state: CombinedState<any> | undefined, action: AnyAction) => {
	if (action.type === HYDRATE) {
		return { ...state, ...action.payload };
	}
	return combineReducers({
		list,
		contents,
	})(state, action);
};
export default reducer;
// export default persistReducer(persistConfig, reducer);
export const persistedReducer = persistReducer(persistConfig, reducer);
