import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type StateType = {
	selectedList: Array<string>;
	login: any;
	audio: any;
	anonymousData: any;
};

const initialState: StateType = {
	selectedList: [],
	login: {
		email: null,
		whether: false,
	},
	audio: '',
	anonymousData: [],
};

const list = createSlice({
	name: 'list',
	initialState,
	reducers: {
		addList(state: StateType, action: PayloadAction<string | any>) {
			state.selectedList = Array.from(new Set([...state.selectedList, ...action.payload]));
		},
		deleteList(state: StateType, action: PayloadAction<string>) {
			state.selectedList = state.selectedList.filter(item => item !== action.payload);
		},
		allClear(state: StateType) {
			state.selectedList = [];
		},
		loginWhether(state: StateType, action: PayloadAction<any>) {
			// state.login = {...state.login, [action.payload.key]: action.payload.value}
			state.login = action.payload;
		},
		audioObj(state: StateType, action: PayloadAction<any>) {
			state.audio = action.payload;
		},
		anonymousAdd(state: StateType, action: PayloadAction<any>) {
			state.selectedList = Array.from(new Set([...state.selectedList, action.payload]));
		},
	},
});

export const { addList, deleteList, allClear, loginWhether, audioObj, anonymousAdd } = list.actions;
export default list.reducer;
