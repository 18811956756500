import { Box, Button, Flex, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList } from '@chakra-ui/react';
import styled from '@emotion/styled';
import Image from 'next/image';

import { useRouter } from 'next/router';

export default function AdminHeader({ headerList }: any) {
	const router = useRouter();

	return (
		<Header>
			<Box className='left' backgroundColor={'#000'} padding={5} cursor={'pointer'}>
				<Image
					src='/images/logo.png'
					width={150}
					height={150}
					alt='회사로고이미지'
					onClick={() => {
						router.push('/');
					}}></Image>
			</Box>
			<Flex className='center'></Flex>
			<MyInfo className='right'>
				<Button
					mr={'20px'}
					as={Button}
					backgroundColor={'#2E7D7C'}
					color={'#fff'}
					onClick={() => {
						router.push('/');
					}}>
					홈페이지 가기
				</Button>
			</MyInfo>
		</Header>
	);
}

const Header = styled.nav`
	font-size: 17px;
	position: absolute;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 70px;
	background: #000;
	padding: 0 5px;
	.header_menu {
		color: #fff;
	}
	.center {
		div {
			margin-right: 50px;
			cursor: pointer;
			&:hover {
				color: yellow;
			}
		}
	}
	.right {
		font-size: 15px;
		padding-right: 10px;
	}
`;

const MyInfo = styled.div``;
