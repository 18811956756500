import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Text,
	useToast,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import ADMIN_HEADER_LIST from '../AdminHeader/AdminHeaderList';
import { useEffect, useState } from 'react';

function SideMenu({ width }: any) {
	const router = useRouter();
	const [activeMenuIndex, setActiveMenuIndex] = useState(0);
	const { asPath } = router;
	const handleOnClick = (path: string) => {
		router.push(path);
	};
	useEffect(() => {
		// 새로고침시 activeMenuIndex 값 설정
		// 주석처리시 새로고침시 activeMenuIndex 값이 0으로 초기화됨
		const startNumber = router.pathname.indexOf('/', 1);
		const endNumber = router.pathname.indexOf('/', startNumber + 1);
		const activeHeader = router.pathname.substring(startNumber + 1, endNumber);
		ADMIN_HEADER_LIST[0].sideMenu.map((el, idx) => {
			if (el.activeMenu === activeHeader) {
				setActiveMenuIndex(idx);
			}
		});
	}, []);
	const toast = useToast();
	return (
		<Container width={width}>
			<Accordion index={activeMenuIndex}>
				{ADMIN_HEADER_LIST[0].sideMenu.map((column: any, idx: any) => {
					return (
						<AccordionItem key={idx + column.name + column.index} border={'none'}>
							<AccordionButton
								position={'relative'}
								className='accItem'
								padding={'13px 0'}
								paddingLeft={'20px'}
								fontWeight={'bold'}
								color={'inherit'}
								_before={asPath == column.path ? { display: 'block !important' } : {}}
								onClick={() => {
									setActiveMenuIndex(idx);
									if (column.children) return;
									handleOnClick(column.path);
								}}
							>
								{column.name}
								{column.children && <AccordionIcon />}
							</AccordionButton>
							{column.children && (
								<AccordionPanel padding={0}>
									{column.children?.map((el: any, columnIdx: number) => {
										return (
											<Box
												key={el.name + columnIdx}
												className={'subColumn'}
												color={'inherit'}
												_before={asPath === el.path ? { display: 'block !important' } : {}}
												onClick={() => {
													if (el.isSoon) {
														return toast({
															title: '준비중입니다.',
															description: '준비중입니다.',
															position: 'bottom',
															duration: 2000,
															isClosable: true,
														});
													}
													handleOnClick(el.path);
												}}
											>
												{el.name}
											</Box>
										);
									})}
								</AccordionPanel>
							)}
						</AccordionItem>
					);
				})}
			</Accordion>
		</Container>
	);
}

const Container = styled.div<any>`
	width: ${props => props.width};
	height: 100vh;
	background: #333;
	color: #fff;
	padding-top: 70px;
	.accItem {
		border: none;
		&::before {
			content: '';
			display: none;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			left: 0px;
			height: 10px;
			width: 30px;
			background: #fbab10;
		}
		&:hover {
		}
	}
	.subColumn {
		width: 100%;
		padding: 10px 0px;
		padding-left: 15%;
		position: relative;
		cursor: pointer;
		&::before {
			content: '';
			display: none;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			left: 0px;
			height: 10px;
			width: 30px;
			background: #fbab10;
		}
		&:hover {
			&::before {
				display: block;
			}
		}
	}
`;

export default SideMenu;
