import Script from 'next/script';
import { FunctionComponent } from 'react';

const Scripts: FunctionComponent = () => {
	return (
		<>
			{/* kakao */}
			<Script src='https://developers.kakao.com/sdk/js/kakao.js' /* crossOrigin="anonymous" */></Script>
			{/* naver */}
			<Script src={'https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.0.js'} />
		</>
	);
};

export default Scripts;
