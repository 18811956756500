const ADMIN_HEADER_LIST = [
	{
		name: '',
		path: '',
		activeHeader: '',
		sideMenu: [
			{
				name: '시스템 관리',
				path: '/',
				activeMenu: 'system',
				children: [
					{ name: '상위 카테고리 관리', path: '/admin/system/0' },
					{ name: '카테고리 관리', path: '/admin/system/1' },
					{ name: '메인영상 관리', path: '/admin/system/2' },
					{ name: '공지사항 관리', path: '/admin/system/3' },
					{ name: '문의사항 관리', path: '/admin/system/4' },
				],
			},
			{
				name: '회원 관리',
				path: '/',
				activeMenu: 'member',
				children: [
					{ name: '회원 목록', path: '/admin/member/0' },
					{ name: '판매 현황', path: '/admin/member/1' },
					{ name: '사용자 통계', path: '/admin/member/2' },
				],
			},
			// {
			// 	name: '콘텐츠 관리',
			// 	path: '/',
			// 	activeMenu: 'content',
			// 	children: [
			// 		{
			// 			name: '콘텐츠 관리',
			// 			path: '/admin/content/0',
			// 		},
			// 	],
			// },
			// {
			// 	name: '게시판 관리',
			// 	path: '/',
			// 	activeMenu: 'community',
			// 	children: [
			// 		{
			// 			name: '설계서없',
			// 			path: '/admin/community/0',
			// 		},
			// 		{
			// 			name: '설계서없',
			// 			path: '/admin/community/1',
			// 		},
			// 	],
			// },
		],
	},
];

export default ADMIN_HEADER_LIST;
