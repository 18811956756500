import '../styles/globals.css';
import '../styles/styles.css';

import Title from '@components/common/title';
import Scripts from '@components/scripts';
import { store } from '@store/configureStore';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';

import { NextPageWithLayout } from '../types';
import Layout from '@components/admin/layout';

interface MyAppProps extends AppProps {
	Component: NextPageWithLayout<{ name: string }>;
}

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			// suspense: true,
			refetchOnWindowFocus: false,
			useErrorBoundary: true,
			retry: 1,
		},
		mutations: {
			useErrorBoundary: true,
			retry: 1,
		},
	},
});

export default function App({ Component, pageProps: { session, ...pageProps } }: MyAppProps) {
	// const getLayout = Component.getLayout ?? (page => page);

	return (
		<>
			<Scripts />
			<SessionProvider session={pageProps.session} refetchOnWindowFocus={true}>
				<QueryClientProvider client={queryClient}>
					<Provider store={store}>
						<Layout>
							<Component {...pageProps} />
						</Layout>
					</Provider>
				</QueryClientProvider>
			</SessionProvider>
		</>
	);
}
