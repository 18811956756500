import { configureStore, Action, ThunkAction } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import reducer, { persistedReducer } from './modules';

const isProduction = process.env.NODE_ENV === 'production';

const makeStore = (context?: any) =>
	configureStore({
		reducer: persistedReducer,
		devTools: process.env.NODE_ENV === 'development',
		middleware: getDefaultMiddleware =>
			getDefaultMiddleware({
				serializableCheck: false,
			}),
	});
export const store = makeStore();

const wrapper = createWrapper(makeStore, { debug: !isProduction });
export default wrapper;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof makeStore>;
